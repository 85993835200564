import React from 'react';

const finishing = {
	'staples': 'Agrafe en coin (max 100 pages)',
	'thermic_pvc': 'Reliure thermique avec couverture PVC (max 350 pages)',
	'thermic_paper': 'Reliure thermique avec couverture carton (max 350 pages)',
	'spiral_pvc': 'Reliure spirale avec couverture PVC (max 450 pages)',
	'spiral_paper': 'Reliure spirale avec couverture carton (max 450 pages)',
};

const FinishingField = ({ record }) => {
	if (!record) {
		return null;
	}

	return (
		<option value={record}>{finishing[record]}</option>
	);
};


export default FinishingField;