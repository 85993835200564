import React from "react";
import logo from '../assets/img/logo.png';

const Header = () => {
    return (
        <header className="header">
            <div className="header__container">
                <div className="logo">
                    <a className="logo__link" href="/">
                        <img src={logo} alt="" className="img" />
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;