import React from 'react';
import { useSelector } from 'react-redux';

import countries from 'services/countries';
import orderImage from "../../../assets/img/pages/order.svg";


const ShippingSummary = () => {
	const currentPrintOrder = useSelector(state => state.currentPrintOrder);
	const country = countries.find(country => country.id === currentPrintOrder.country);

	return (
		<>
			<div className="col-12 col-md-9 checkout__shipping-summary">
				<h2 className="title-h2">Récapitulatif livraison</h2>
				{ currentPrintOrder.ship_method === 'duc' &&
					<div>
						<h3>La commande est à récupérer à la DUC, Grand-Rue 2-14, 1348 Louvain-la-Neuve</h3>
					</div>
				}
				{ currentPrintOrder.ship_method === 'bpost' &&
					<div>
						<h3>La commande sera livrée via BPOST à l'adresse suivante</h3>
						<ul className="checkout__ul">
							<li className="checkout__li checkout__li--name">{currentPrintOrder.firstname} {currentPrintOrder.lastname}</li>
							<li className="checkout__li">{currentPrintOrder.street} {currentPrintOrder.street_number} {currentPrintOrder.street_box && `bte ${currentPrintOrder.street_box}`}</li>
							<li className="checkout__li">{currentPrintOrder.street_bis}</li>
							<li className="checkout__li">{currentPrintOrder.zip_code} {currentPrintOrder.city}</li>
							<li className="checkout__li checkout__li--country">{country?.label}</li>
							<li className="checkout__li">{currentPrintOrder.email}</li>
							<li className="checkout__li">{currentPrintOrder.phone_number}</li>
						</ul>
					</div>
				}
			</div>
			<div className="col-3 container-img">
				<img src={orderImage} alt="" className="img"/>
			</div>
		</>
	);
};

export default ShippingSummary;