import React from "react";
import { useHistory } from 'react-router-dom';

const Prices = () => {
    const history = useHistory();

    return (
        <div className="prices">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="title-h1">Tarifs</h1>
                    </div>
                    <div className="col-12 col-md-7 prices__container">
                        <h2 className="title-h2">Général</h2>
                        <p>
                            Minimum facturable par fichier&nbsp;: 2,00€
                        </p>
                    </div>
                    <div className="col-12 col-md-8">
                        <div className="row">
                            <div className="col-12 prices__container">
                                <h2 className="title-h2">Impressions couleur</h2>
                                <table className="table">
                                    <thead className="table__thead thead--variant">
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant thead__td--variant">Par copie à partir de</td>
                                        <td className="table__td--variant thead__td--variant">Recto</td>
                                        <td className="table__td--variant thead__td--variant">Recto / Verso</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">1 à 250</td>
                                        <td className="table__td--variant" data-label="Recto">0,20€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,185€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">251 à 500</td>
                                        <td className="table__td--variant" data-label="Recto">0,18€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,169€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">501 à 1000</td>
                                        <td className="table__td--variant" data-label="Recto">0,17€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,159€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">>= 1000</td>
                                        <td className="table__td--variant" data-label="Recto">0,16€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,15€</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 prices__container">
                                <h2 className="title-h2">Impressions noir & blanc</h2>
                                <table className="table">
                                    <thead className="table__thead thead--variant">
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant thead__td--variant">Par copie à partir de</td>
                                        <td className="table__td--variant thead__td--variant">Recto</td>
                                        <td className="table__td--variant thead__td--variant">Recto / Verso</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">1 à 2000</td>
                                        <td className="table__td--variant" data-label="Recto">0,040€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,037€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">2001 à 5000</td>
                                        <td className="table__td--variant" data-label="Recto">0,036€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,033€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">5001 à 10.000</td>
                                        <td className="table__td--variant" data-label="Recto">0,034€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,031€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant" data-label="Par copie à partir de">>= 10000</td>
                                        <td className="table__td--variant" data-label="Recto">0,032€</td>
                                        <td className="table__td--variant" data-label="Recto / Verso">0,030€</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 col-lg-9 prices__container">
                                <h2 className="title-h2">Finitions</h2>
                                <table className="table">
                                    <thead className="table__thead thead--variant">
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant thead__td--variant">Description</td>
                                        <td className="table__td--variant thead__td--variant">Prix</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">Reliure thermique avec couverture PVC :</td>
                                        <td className="table__td--variant">2,50€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">Reliure thermique avec couverture carton :</td>
                                        <td className="table__td--variant">2,50€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">Reliure spirale avec couverture PVC :</td>
                                        <td className="table__td--variant">2,50€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">Reliure spirale avec couverture carton :</td>
                                        <td className="table__td--variant">2,50€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">Agrafe en coin :</td>
                                        <td className="table__td--variant">0,50€</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 col-lg-9 prices__container">
                                <h2 className="title-h2">Frais de port</h2>
                                <table className="table">
                                    <thead className="table__thead thead--variant">
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant thead__td--variant">Pays</td>
                                        <td className="table__td--variant thead__td--variant">Prix</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">Belgique :</td>
                                        <td className="table__td--variant">5,95€</td>
                                    </tr>
                                    <tr className="table__tr--variant">
                                        <td className="table__td--variant">France, Luxembourg, Pays-Bas, Allemagne :</td>
                                        <td className="table__td--variant">8,95€</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 prices__container">
                        <button type="button" className="prices__button button" onClick={ () => { history.push('/') } }>Retour à l'accueil</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Prices;
