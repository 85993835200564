import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteLine, fetchPrintOrder } from 'actions/printOrders';


const Document = ({ data, editable }) => {
    const dispatch = useDispatch();

    const handleDeleteLine = () => {
        if(window.confirm('Voulez-vous supprimer ce document ?')) {
            dispatch(deleteLine(data.id));
            dispatch(fetchPrintOrder());
        }
    };

    const finishing = () => {
        switch (data.finishing) {
            case 'thermic_pvc': return 'Reliure thermique avec couverture PVC';
            case 'thermic_paper': return 'Reliure thermique avec couverture carton';
            case 'spiral_pvc': return 'Reliure spirale avec couverture PVC';
            case 'spiral_paper': return 'Reliure spirale avec couverture carton';
            case 'staples': return 'Agrafe en coin';
            default: return 'Aucune';
        }
    };

    const paper = () => {
        switch (data.paper_type) {
            case 'ecolabel': return 'Papier blanc Ecolabel';
            case 'recycled': return 'Papier recyclé';
            default: return 'Papier recyclé';
        }
    };

    return (
        <tr className="table__tr">
            <td className="table__td table__td--type" data-label="Nom du document">{data.document?.filename}</td>
            <td className="table__td" data-label="Recto / Verso">
                {data.recto_verso?"Recto/Verso":"Recto"}
            </td>
            <td className="table__td" data-label="Couleur">
                {data.color?"Couleur":"N/B"}
            </td>
            <td className="table__td" data-label="Finition">
                {paper()}
            </td>
            <td className="table__td" data-label="Type de papier">
                {finishing()}
            </td>
            <td className="table__td" data-label="Nbr de pages">
                {data.page_count} pages
            </td>
            <td className="table__td" data-label="Nombre de copies">
                X {data.copies}
            </td>
            <td className="table__td" data-label="Prix HTVA&nbsp;:">
                {data?.total_incl_vat?.toFixed(2)}€
            </td>
            {editable && (
                <td className="table__td table__td--delete">
                    <button className="print-order-summary__delete button button--delete" type="button" onClick={handleDeleteLine}>Supprimer</button>
                </td>
            )}
        </tr>
    );
};

const PrintOrderSummary = ({ editable }) => {
    const currentPrintOrder = useSelector(state => state.currentPrintOrder);

    return (
        <div className="print-order-summary col-12">
            {editable && <h3 className="title-h3 print-order-summary__title">Récapitulatif</h3>}
            <table className="table">
                <thead className="table__thead thead">
                <tr className="table__tr">
                    <td className="table__td thead__td">Nom du document</td>
                    <td className="table__td thead__td">Recto / Verso</td>
                    <td className="table__td thead__td">Couleur</td>
                    <td className="table__td thead__td">Type de papier</td>
                    <td className="table__td thead__td">Finition</td>
                    <td className="table__td thead__td">Nbr de pages</td>
                    <td className="table__td thead__td">Nombre de copies</td>
                    <td className="table__td thead__td">Prix TTC</td>
                    {editable && <td className="table__td thead__td">&nbsp;</td>}
                </tr>
                </thead>
                <tbody className="table__tbody">
                {currentPrintOrder.lines.map((data, key) => (
                    <Document key={key} data={data} editable={editable} />
                ))}
                </tbody>
            </table>
            <div className="print-order-summary__total">
                <span className="print-order-summary__total-TTC">Frais de transport :  {currentPrintOrder.shipping_price?.toFixed(2)} €</span>
                <span className="print-order-summary__total-fee">Total TTC à régler : {currentPrintOrder.total_incl_vat?.toFixed(2)} €</span>
            </div>
        </div>
    );
};

PrintOrderSummary.defaultProps = {
    editable: true
};

export default PrintOrderSummary;
