import { ADD_PRINT_ORDER, FETCH_PRINT_ORDER, UPDATE_PRINT_ORDER, CANCEL_PRINT_ORDER, ADD_PRINT_ORDER_LINE, UPDATE_PRINT_ORDER_LINE, DELETE_PRINT_ORDER_LINE } from 'actions/printOrders';

const initialState = {
	lines: [],
	email: '',
	firstname: '',
	lastname: '',
	street: '',
	street_bis: '',
	zipcode: '',
	city: '',
	phone_number: ''
};

export default function(state=initialState, action) {
	switch (action.type) {
		case ADD_PRINT_ORDER:
			return initialState;
		case FETCH_PRINT_ORDER:
			return action.payload;
		case UPDATE_PRINT_ORDER:
			return { ...state, ...action.payload, lines: state.lines };
		case CANCEL_PRINT_ORDER:
			return initialState;
		case ADD_PRINT_ORDER_LINE:
			return { ...state, lines: [...state.lines, action.payload] };
		case UPDATE_PRINT_ORDER_LINE:
			return {
				...state,
				lines: [
					...state.lines.map(line => (
						line.id === action.payload.id ? { ...line, ...action.payload } : line
					))
				]
			};
		case DELETE_PRINT_ORDER_LINE:
			return { ...state, lines: state.lines.filter(line => (line.id !== action.payload)) };
		default:
			return state;
	}
};