import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';


const CheckoutForm = ({paymentIntent}) => {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [hasError, setHasError] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements || !paymentIntent) {
            return;
        }

        setLoading(true)

        const error = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href
            }
        })

        if (error) {
            setHasError(true)
            localStorage.removeItem('token')
        }
    }

    return (
        <form onSubmit={handleSubmit} className="col-12 col-md-8">
            <h2 className="title-h2">Paiement</h2>
            <p>
                En cliquant sur le bouton « payer », vos données sont redirigées vers la plateforme Stripe. Ce
                service en ligne vous permet d’effectuer votre paiement en toute sécurité et rapidement. Aucune
                donnée de paiement ne transite par nos serveurs. Après confirmation de votre paiement, vous
                recevez une confirmation de paiement par email (n’oubliez pas de vérifier vos spams).
            </p>
            {hasError && <div className="error">Une erreur c'est produite lors du paiement</div>}
            {!hasError && (<>
                <PaymentElement id="payment-element" options={{layout: "tabs"}}/>
                <div className="checkout__payment">
                    <div className="checkout__container-checkboxes">
                        <div className="form__fieldset--row">
                            <label htmlFor="first" className="form__label"><a
                                href="https://www.ciaco.coop/papeterie/conditions-generales-de-vente"
                                target="_blank"
                                rel="noopener noreferrer">J’accepte les conditions générales de vente</a></label>
                            <input type="checkbox" id="first" className="input checkbox" required={true}/>
                        </div>
                        <div className="form__fieldset--row">
                            <label htmlFor="sec" className="form__label"><a
                                href="https://www.ciaco.coop/coop/mentions-legales" target="_blank"
                                rel="noopener noreferrer">J’accepte la politique de confidentialité</a></label>
                            <input type="checkbox" id="sec" className="input checkbox" required={true}/>
                        </div>
                    </div>

                    <button disabled={loading || !stripe || !elements} type="submit" className="button button--pay">
                        Payer
                    </button>
                </div></>
            )}

        </form>
    );
};

export default CheckoutForm;