import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { addLine } from 'actions/printOrders';

const DocumentDropzone = ({ onDropDocuments, onUploadProgress }) => {
	const dispatch = useDispatch();
	const onDrop = useCallback(acceptedFiles => {
		const documents = acceptedFiles.map(file => ({ file, tmp_id: Math.round(Math.random() * 1000) }));
		if (onDropDocuments) {
			onDropDocuments(documents);
		}

		documents.forEach((document) => {
			const formData = new FormData();
			formData.append('document_file', document.file);

			const options = {
				onUploadProgress: progressEvent => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total);

					if (onUploadProgress) {
						onUploadProgress(document.tmp_id, percent);
					}
				}
			};

			dispatch(addLine({ data: formData, tmp_id: document.tmp_id, options }));
		});
	}, [onDropDocuments, onUploadProgress, dispatch]);
	const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'application/pdf' });

	return (
		<div {...getRootProps()} className="upload__drop-zone-container">
			<input {...getInputProps()} />
			<div className="upload__drag-drop">
				<span className="upload__text">
					Déposer ici<br />
                    ou<br />
					<span className="upload__text--red">Sélectionner vos fichiers</span>
				</span>
			</div>
			<div className="upload__drag-drop upload__drag-drop--responsive">
				<span className="upload__text">Sélectionner vos fichiers</span>
			</div>
		</div>
	)
};

export default DocumentDropzone;