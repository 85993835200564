import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';

import { updatePrintOrder, updatePrintOrderAsync } from 'actions/printOrders';
import PrintOrderSummary from 'components/printOrders/PrintOrderSummary';
import Breadcrumb from 'components/Breadcrumb';

import { fetchPrintOrder } from 'actions/printOrders';
import countries from 'services/countries';

import shippingImage from 'assets/img/pages/shipping.svg';


const PrintOrderForm = () => {
    const currentPrintOrder = useSelector(state => state.currentPrintOrder);
    const dispatch = useDispatch();
    const history = useHistory();
    const [order, setOrder] = useState({
        email: '',
        firstname: '',
        lastname: '',
        street: '',
        street_bis: '',
        zip_code: '',
        city: '',
        phone_number: '',
        ship_method: '',
        ...currentPrintOrder,
        country: currentPrintOrder.country ?? 'BE',
    });

    useEffect(() => {
        dispatch(fetchPrintOrder());
    }, [dispatch]);

    useEffect(() => {
        if(currentPrintOrder.status && currentPrintOrder.status !== 'new') {
            history.push('/orders/checkout');
        }
        setOrder({ ...currentPrintOrder, country: currentPrintOrder.country ?? 'BE' });
    }, [currentPrintOrder, history]);

    const handleChange = (property, e) => {
        setOrder({ ...order, [property]: e.target.value });
    };

    const handleChangeCountry = (e) => {
        setOrder({ ...order, country: e.target.value });
        const formData = getFormData();
        formData.set('country', e.target.value);
        dispatch(updatePrintOrder(formData));
    };

    const handleChangeShipMethod = (e) => {
        setOrder({ ...order, ship_method: e.target.value });
        const formData = getFormData();
        formData.set('ship_method', e.target.value);
        dispatch(updatePrintOrder(formData));
    };

    const getFormData = () => {
        const formData = new FormData();
        formData.append('email', order.email ?? '');
        formData.append('firstname', order.firstname ?? '');
        formData.append('lastname', order.lastname ?? '');
        formData.append('street', order.street ?? '');
        formData.append('street_number', order.street_number ?? '');
        order.street_box && formData.append('street_box', order.street_box);
        order.street_bis && formData.append('street_bis', order.street_bis);
        formData.append('zip_code', order.zip_code ?? '');
        formData.append('city', order.city ?? '');
        formData.append('country', order.country);
        formData.append('phone_number', order.phone_number ?? '');
        formData.append('ship_method', order.ship_method ?? '');
        return formData;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = getFormData();

        dispatch(updatePrintOrder(formData));
        history.push('/orders/checkout');
    };

    const handleBack = () => {
        // FIXME create new action just for updating the state and not saving it in db?
        dispatch(updatePrintOrderAsync(order));
        history.push('/orders/new');
    };

    return (
        <div className="print-order-form">
            <form onSubmit={handleSubmit} className="container">
                <div className="row">
                    <div className="col-12 col-md-9">
                        <h1 className="title-h1">Commande d'imprimés en ligne</h1>
                        <Breadcrumb step={2} />
                        <h2 className="title-h2">Informations</h2>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-12 col-md-7">
                        <div className="form">
                            <p>
                                Les livraisons en Belgique se font par courrier suivi Bpost. En France, Luxembourg, Allemagne et Pays-bas elles se font par courrier ordinaire.
                                Il est également possible de récupérer sa commande à la DUC.
                            </p>
                            <div className="form__fieldset">
                                <label htmlFor="email" className="form__label">Email*</label>
                                <input type="email" id="email" className="input" placeholder="&nbsp;" value={order.email ?? ''} onChange={handleChange.bind(this, 'email')} required={true} />
                            </div>
                            <div className="form__fieldset--md">
                                <label htmlFor="name" className="form__label">Nom*</label>
                                <input type="text" id="name" className="input" placeholder="&nbsp;" value={order.lastname ?? ''} onChange={handleChange.bind(this, 'lastname')} required={true} />
                            </div>
                            <div className="form__fieldset--md">
                                <label htmlFor="name" className="form__label">Prénom*</label>
                                <input type="text" id="name" className="input" placeholder="&nbsp;" value={order.firstname ?? ''} onChange={handleChange.bind(this, 'firstname')} required={true} />
                            </div>
                            <div className="form__fieldset--lg">
                                <label htmlFor="street" className="form__label">Rue*</label>
                                <input type="text" id="street" className="input" placeholder="&nbsp;" value={order.street ?? ''} onChange={handleChange.bind(this, 'street')} required={true} />
                            </div>
                            <div className="form__fieldset--xs">
                                <label htmlFor="street_number" className="form__label">Numéro*</label>
                                <input type="text" id="street_number" className="input" placeholder="&nbsp;" value={order.street_number ?? ''} onChange={handleChange.bind(this, 'street_number')} required={true} />
                            </div>
                            <div className="form__fieldset--xs">
                                <label htmlFor="street_box" className="form__label">Boîte postale</label>
                                <input type="text" id="street_box" className="input" value={order.street_box ?? ''} onChange={handleChange.bind(this, 'street_box')} />
                            </div>
                            {/*<div className="form__fieldset--xl">*/}
                            {/*    <label htmlFor="street_bis" className="form__label">Adresse complémentaire</label>*/}
                            {/*    <input type="text" id="street_bis" className="input" value={order.street_bis ?? ''} onChange={handleChange.bind(this, 'street_bis')} />*/}
                            {/*</div>*/}
                            <div className="form__fieldset--md">
                                <label htmlFor="zip_code" className="form__label">Code postal*</label>
                                <input type="number" id="zip_code" className="input" placeholder="&nbsp;" value={order.zip_code ?? ''} onChange={handleChange.bind(this, 'zip_code')} required={true} />
                            </div>
                            <div className="form__fieldset--md">
                                <label htmlFor="city" className="form__label">Ville*</label>
                                <input type="text" id="city" className="input" placeholder="&nbsp;" value={order.city ?? ''} onChange={handleChange.bind(this, 'city')} required={true} />
                            </div>
                            <div className="form__fieldset">
                                <label htmlFor="country" className="form__label">Pays*</label>
                                <select name="country" value={order.country} className="select" onChange={handleChangeCountry}>
                                    {countries.map(country => (
                                        <option value={country.id} key={country.id}>{country.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form__fieldset">
                                <label htmlFor="phone" className="form__label">Téléphone*</label>
                                <input type="phone" id="phone" className="input" placeholder="&nbsp;" value={order.phone_number ?? ''} onChange={handleChange.bind(this, 'phone_number')} required={true} />
                            </div>
                            <div className="form__fieldset">
                                <label htmlFor="ship_method" className="form__label">Livraison*</label>
                                <div>
                                    <label htmlFor="ship_method_bpost">Livraison à domicile par BPOST (5.95€)</label>
                                    <input type="radio" id="ship_method_bpost" name="ship_method" value="bpost" onChange={handleChangeShipMethod} checked={order.ship_method === "bpost"} />
                                </div>
                                <div>
                                    <label htmlFor="ship_method_duc">Récuperer la commmande à la DUC, Grand-Rue 2-14, 1348 Louvain-la-Neuve</label>
                                    <input type="radio" id="ship_method_duc" name="ship_method" value="duc" onChange={handleChangeShipMethod} checked={order.ship_method === "duc"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 print-order-form__container-image">
                        <img src={shippingImage} alt="" className="img"/>
                    </div>
                    <PrintOrderSummary />
                    <div className="col-12">
                        <p>
                            La DUC est un service de la coopérative CIACO. La CIACO recueille vos données afin de traiter votre demande et la livraison de votre commande. Conformément à la loi, vous bénéficiez d’un droit d’accès, de rectification, d’opposition et de suppression de vos données. Vous seul pouvez exercer vos droits sur vos propres données en envoyant votre demande par email à <a
                            href="mailto:info@ciaco.coop">info@ciaco.coop</a>.
                        </p>
                        <p>
                            Pour de plus amples informations sur notre politique de confidentialité et la façon dont nous veillons à protéger et respecter votre vie privée, vous pouvez consulter notre <a href="https://www.duc.be/pages/legal" target="_blank" className="link--underline" rel="noopener noreferrer">politique de confidentialité</a>. En envoyant ce formulaire, vous donnez l’autorisation à la CIACO de stocker les informations personnelles qui sont fournies ci-dessus et à les traiter pour vous fournir le service demandé. Nous utilisons Stripe comme plate-forme de paiement en ligne. <br/>En soumettant ce formulaire, vous acceptez que les informations que vous fournissez soient transférées à Stripe Inc. pour traitement conformément à <a
                            href="https://stripe.com/fr-be/privacy" target="_blank" className="link--underline" rel="noopener noreferrer">leur politique de confidentialité et conditions</a>.
                        </p>
                    </div>
                    <div className="col-12 print-order-form__container-button">
                        <button type="button" className="button button--invert" onClick={handleBack}>Revenir à la commande</button>
                        <button type="submit" className="button" disabled={currentPrintOrder.lines.length > 0 ? false : true}>Suivant</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PrintOrderForm;