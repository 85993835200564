import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

// TODO: fix the warning in the console
const TooltipButton = ({ id, children }) => (
	<OverlayTrigger
		trigger={["click", "hover"]}
		placement="right"
		overlay={
			<Tooltip id={id}>
				{children}
			</Tooltip>
		}>
		<button type="button" className="button--tooltip">&nbsp;</button>
	</OverlayTrigger>
);

export default TooltipButton;