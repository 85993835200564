import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addPrintOrder, fetchPrintOrder } from 'actions/printOrders';

import overviewImage from '../../assets/img/pages/overview.svg';


const Overview = () => {
    const currentPrintOrder = useSelector(state => state.currentPrintOrder);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchPrintOrder());
    }, [dispatch]);

    const handleClick = async () => {
        if(!currentPrintOrder.id || currentPrintOrder.status !== 'new') {
            await dispatch(addPrintOrder());
        }
        await history.push('/orders/new');
    };

    return (
        <div className="overview">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="title-h1">Service DUC online</h1>
                    </div>
                    <div className={"col-12 col-md-11 col-lg-9"}>
                        <p className="overview__paragraph">
                            Profitez dès maintenant de notre solution en ligne qui vous permettra de commander et de faire livrer chez vous :
                        </p>
                        <ul className="overview__ul">
                            <li className="overview__li">des livres universitaires et scientifiques disponibles en stock (prix officiel de l'éditeur)</li>
                            <li className="overview__li">des syllabus de cours UCLouvain (toutes facultés sauf sciences médicales, droit et EPL), Ephec et  ISAT, au prix habituel</li>
                            <li className="overview__li">des impressions de fichiers PDF</li>
                        </ul>
                        <button type="button" className="prices__button button" onClick={ () => { history.push('/prices') } }>Tarifs</button>
                        <span className="overview__alert">
                            Frais d'envoi: 5.95€  pour la Belgique et 8.95€ pour la France, le Luxembourg, l'Allemagne et les Pays-Bas. Le retrait en magasin est gratuit.
                        </span>
                        <div className="overview__container-button">
                            <button type="button" className="overview__button button" onClick={handleClick}>Commandez ici vos impressions</button>
                            <a href="https://uclouvain.duc.be" target="_blank" rel="noopener noreferrer" className="overview__button button">Commandez ici vos livres / syllabus UCLouvain</a>
                            <a href="https://ephec.duc.be" target="_blank" rel="noopener noreferrer" className="overview__button button">Commandez ici vos livres / syllabus Ephec</a>
                            <a href="https://isat.duc.be" target="_blank" rel="noopener noreferrer" className="overview__button button">Commandez ici vos livres / syllabus Isat</a>
                        </div>
                    </div>
                    <div className="col-2 d-none d-lg-block col-md-3 container-img">
                        <img src={overviewImage} alt="" className={"img"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
