import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import DocumentDropzone from 'components/printOrders/DocumentDropzone';
import TooltipButton from 'components/common/TooltipButton';

import { addPrintOrder, fetchPrintOrder, deleteLine, updateLine } from 'actions/printOrders';

import uploadImage from '../../assets/img/pages/overview.svg';
import Breadcrumb from "../Breadcrumb";

import FinishingField from '../fields/FinishingField';


const UploadForm = () => {
    const currentPrintOrder = useSelector(state => state.currentPrintOrder);
    const [lines, setLines] = useState(currentPrintOrder.lines);
    const dispatch = useDispatch();
    const history = useHistory();

	useEffect(() => {
		const fetchData = async () => {
            const response = await dispatch(fetchPrintOrder());
            if (!response) {
                await dispatch(addPrintOrder());
            }
		};

		fetchData();
	}, [dispatch]);

    useEffect(() => {
        if (!currentPrintOrder.id) {
            dispatch(fetchPrintOrder());
        }
        else if (currentPrintOrder.status !== 'new') {
            history.push('/orders/checkout');
        }
    }, [dispatch, currentPrintOrder.id, currentPrintOrder.status, history]);

    useEffect(() => {
        if (lines.length > 0) {
            setLines(prevLines => (
                prevLines.map(line => {
                    const newLine = currentPrintOrder.lines.find((l => ((l.tmp_id && l.tmp_id === line.tmp_id) || line.id === l.id)));
                    return newLine ? { ...line, ...newLine } : line;
                })
            ));
        }
        else {
            setLines(currentPrintOrder.lines);
        }
    }, [currentPrintOrder.lines, lines.length]);

    const handleChangeLine = (id, property, e) => {
        const value = e.target.type === 'checkbox' ? !!e.target.checked : e.target.value;
        setLines(prevLines => {
            let updatedLine;
            const newLines = prevLines.map(line => {
                if (line.id === id) {
                    updatedLine = { ...line, [property]: value };
                    return updatedLine;
                }
                return line;
            });

            if (updatedLine) {
                const formData = new FormData();
                formData.append('color', updatedLine.color);
                formData.append('recto_verso', updatedLine.recto_verso);
                if (updatedLine.finishing) {
                    formData.append('finishing', updatedLine.finishing);
                }
                if (updatedLine.paper_type) {
                    formData.append('paper_type', updatedLine.paper_type);
                }
                formData.append('copies', updatedLine.copies);
                dispatch(updateLine(id, formData));
            }

            return newLines;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        lines.forEach(line => {
            const formData = new FormData();
            formData.append('color', line.color);
            formData.append('recto_verso', line.recto_verso);
            if (line.finishing) {
                formData.append('finishing', line.finishing);
            }
            if (line.paper_type) {
                formData.append('paper_type', line.paper_type);
            }
            formData.append('copies', line.copies);
            dispatch(updateLine(line.id, formData));
        });
        history.push('/orders/shipping');
    };

    const handleDeleteLine = (id) => {
        if (window.confirm('Voulez-vous supprimer ce document ?')) {
            dispatch(deleteLine(id));
            setLines(prevLines => (
                prevLines.filter(line => line.id !== id)
            ));
        }
    };

    const handleDropDocuments = (files) => {
        let newLines = [];
        files.forEach((line) => {
            newLines.push({
                tmp_id: line.tmp_id,
                copies: 1,
                color: false,
                recto_verso: false,
                document: {
                    filename: line.file.name,
                    percent: 0
                }
            });
        });

        setLines((prevLines) => ([...prevLines, ...newLines]));
    };

    const onUploadProgress = (tmp_id, percent) => {
        setLines((prevLines) => (
            prevLines.map(line => (
                line.tmp_id === tmp_id ? { ...line, document: { ...line.document, percent } } : line
            ))
        ));
    };

    return (
        <div className="upload">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="title-h1">Commande d'imprimés en ligne</h1>
                    </div>
                    <div className="col-12 col-md-9">
                        <Breadcrumb step={1} />
                        <h2 className="title-h2">Ajouter un document PDF</h2>
                        <div className="upload__drop-zone">
                            <DocumentDropzone onDropDocuments={handleDropDocuments} onUploadProgress={onUploadProgress} />
                        </div>
                    </div>
                    <div className="col-3 container-img">
                        <img src={uploadImage} alt="" className="img" />
                    </div>
                    <div className="col-12">
                        <form className="w-100" onSubmit={handleSubmit}>
                            <h2 className="title-h2">Liste des documents</h2>
                            <h3 className="upload__title-h3">Vous avez {lines.length} {lines.length < 2 ? 'document' : 'documents'}</h3>
                            <div className="upload__uploading">
                                <table className="table">
                                    <thead className="table__thead thead">
                                        <tr className="table__tr">
                                            <td className="table__td thead__td">Nom du document</td>
                                            <td className="table__td thead__td">Nbr de pages</td>
                                            <td className="table__td thead__td">
                                                <span className="thead__tooltip">
                                                    <span>Recto / Verso</span>
                                                    <TooltipButton id="tooltip-recto">
                                                        Le recto/verso se fait toujours sur le grand côté (format portrait)
                                                </TooltipButton>
                                                </span>
                                            </td>
                                            <td className="table__td thead__td">
                                                <span className="thead__tooltip">
                                                    <span>Couleurs</span>
                                                    <TooltipButton id="tooltip-color">
                                                        En cochant cette case toutes vos pages seront imprimées en couleurs. Séparez votre fichier en deux si vous souhaitez qu’une partie seulement soit imprimée en couleurs.
                                                </TooltipButton>
                                                </span>
                                            </td>
                                            <td className="table__td thead__td">
                                                <span className="thead__tooltip">
                                                    <span>Type de papier</span>
                                                    <TooltipButton id="tooltip-paper">
                                                        Tous nos papiers bénéficient d’un label écologique. Le grammage est de 80 g/m2, de légères variations sont possibles.
                                                </TooltipButton>
                                                </span>
                                            </td>
                                            <td className="table__td thead__td">
                                                <span className="thead__tooltip">
                                                    <span>Finition</span>
                                                    <TooltipButton id="tooltip-finishing">
                                                        La première page de votre fichier sera répétée sur le carton de couverture de votre reliure.
                                                </TooltipButton>
                                                </span>
                                            </td>
                                            <td className="table__td thead__td">Nombre de copies</td>
                                            <td className="table__td thead__td">Prix TTC</td>
                                            <td className="table__td thead__td">&nbsp;</td>
                                        </tr>
                                    </thead>
                                    <tbody className="table__tbody">
                                        {lines.map((line, index) => (
                                            <tr className="table__tr" key={`line_${index}`}>
                                                <td className="table__td table__td--type">{line.document?.filename}</td>
                                                {line.document?.percent < 100 ? (
                                                    <td className="table__td">
                                                        <span className="upload__progress progress">
                                                            <span className="progress__bar">&nbsp;</span>
                                                            <span className="progress__bar progress__bar--red" style={{ 'width': `${line.document.percent}%` }}>&nbsp;</span>
                                                            <span className="progress__percent" style={{ 'left': `${line.document.percent}%` }}>{line.document.percent}%</span>
                                                            <button className="progress__delete button button--delete" type="button">Supprimer</button>
                                                        </span>
                                                    </td>
                                                ) : (
                                                        <>
                                                            <td className="table__td" data-label="Nbr de pages">
                                                                {line.page_count} pages
                                                    </td>
                                                            <td className="table__td" data-label="Recto / Verso">
                                                                <label htmlFor={`recto-${index}`}>&nbsp;</label>
                                                                <input type="checkbox" name={`recto-${index}`} className="form__input checkbox" defaultChecked={line.recto_verso} onChange={handleChangeLine.bind(this, line.id, 'recto_verso')} />
                                                            </td>
                                                            <td className="table__td" data-label="Couleurs">
                                                                {
                                                                    line.color === -1 ? (
                                                                        <span className="upload__color">N/B</span>) :
                                                                        (
                                                                            <>
                                                                                <label htmlFor={`color-${index}`}>&nbsp;</label>
                                                                                <input type="checkbox" name={`color-${index}`} className="form__input checkbox" defaultChecked={line.color} onChange={handleChangeLine.bind(this, line.id, 'color')} />
                                                                            </>
                                                                        )
                                                                }
                                                            </td>
                                                            <td className="table__td" data-label="Type de papier">
                                                                <select name={`paper-type-${index}`} className="upload__paper-type" value={line.paper_type} onChange={handleChangeLine.bind(this, line.id, 'paper_type')}>
                                                                    <option value="ecolabel">Papier blanc Ecolabel</option>
                                                                    <option value="recycled">Papier recyclé</option>
                                                                </select>
                                                            </td>
                                                            <td className="table__td" data-label="Finition">
                                                                {line.available_bindings?.length === 0 && `Reliures limitées à 450 pages`}
                                                                {line.available_bindings?.length > 0 &&
                                                                    <select name={`finishing-${index}`} className="upload__finishing" value={line.finishing} onChange={handleChangeLine.bind(this, line.id, 'finishing')}>
                                                                        <option value="without">Aucune</option>
                                                                        {line.available_bindings.map(binding => <FinishingField record={binding} key={`finishing_field_${binding}`} />)}
                                                                    </select>
                                                                }
                                                            </td>
                                                            <td className="table__td" data-label="Nombre de copies">
                                                                <input type="number" name={`copies-${index}`} className="form__input form__input--number" value={line.copies} onChange={handleChangeLine.bind(this, line.id, 'copies')} min="1" />
                                                            </td>
                                                            <td className="table__td">
                                                                {line?.total_incl_vat?.toFixed(2)}€
                                                    </td>
                                                            <td className="table__td table__td--delete">
                                                                <button className="upload__delete button button--delete" type="button" onClick={handleDeleteLine.bind(this, line.id)}>Supprimer</button>
                                                            </td>
                                                        </>
                                                    )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="alert alert-danger">
                                <p>
                                    Seuls les fichiers PDF au format A4 sont acceptés. Ils doivent être entièrement prêts à l’impression. Aucune modification n’est possible. Pour toutes questions contactez-nous&nbsp;: <a
                                        href="mailto:ciacopy@ciaco.coop" className="link--underline">ciacopy@ciaco.coop</a>
                                </p>
                                <p>
                                    Un minimum de 2,-€ est facturé par fichier. Consultez notre <Link to={'/prices'} className="link--underline" style={{ color: "red" }}>tarif</Link>.
                                    Pour faire des économies, n'hésitez pas à fusionner vos pdf avec, par exemple, le site <a href="https://smallpdf.com/fr/fusionner-pdf" className="link--underline" target="_blank" rel="noopener noreferrer">smallpdf.com</a>
                                </p>
                            </div>
                            <button type="submit" className="orm__button button button--submit" disabled={lines.length > 0 ? false : true}>Passer au récapitulatif de ma commande</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadForm;