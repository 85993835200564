import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import ShippingSummary from './ShippingSummary';
import PrintOrderSummary from '../PrintOrderSummary';

import {fetchPrintOrder} from 'actions/printOrders'
import Breadcrumb from "../../Breadcrumb";

import {STRIPE_PUBLIC_KEY} from '../../../services/settings';
import * as services from "../../../services/api";
import CheckoutFailed from "./CheckoutFailed";
import CheckoutSuccess from "./CheckoutSuccess";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutContainer = () => {
    const url = new URL(window.location.href);
    const order = useSelector(state => state.currentPrintOrder);
    const [paymentIntent, setPaymentIntent] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        dispatch(fetchPrintOrder()).then((result) => {
            const requiredFields = ['firstname', 'lastname', 'email', 'street', 'street_number', 'zip_code', 'city', 'phone_number'];
            const isAnyFieldMissing = requiredFields.some(field => !result[field]);
            if (result && isAnyFieldMissing)
                history.push('/orders/shipping');
        });
    }, [dispatch, history]);

    useEffect(() => {
        if (order.status === 'new')
            updatePaymentIntent();
    }, [order]);

    const updatePaymentIntent = async () => {
        for (let i = 0; i <5; i++) {
            const res = await services.confirmPrintOrder()
            if (res?.payment_intent) {
                setPaymentIntent(prev => ({...prev, ...res.payment_intent}));
                setClientSecret(res.payment_intent.client_secret);
                return;
            }
            await new Promise(resolve => setTimeout(resolve, 3000));
        }
    }

    const options = {
        clientSecret
    }

    const DisplayOrderProcess = () => {
        if (paymentIntent?.status === 'canceled' || url.searchParams.get('redirect_status') === 'failed')
            return <CheckoutFailed/>;

        if (paymentIntent?.status === 'succeeded' || url.searchParams.get('redirect_status') === 'succeeded')
            return <CheckoutSuccess/>;

        const orderStatus = order?.status

        if (orderStatus === 'new' && clientSecret) {
            return (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm paymentIntent={paymentIntent}/>
                </Elements>
            );
        }

        if (['paid', 'printed', 'printing'].includes(orderStatus)) {
            return (
                <p>Votre commande est en cours de traitement</p>
            );
        }

        if (orderStatus === 'shipped') {
            return (
                <p>Votre commande a été expédiée</p>
            );
        }

        return (
            <p>Loading...</p>
        );
    }

    return (
        <div className="checkout">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="title-h1">Commande DUC Online</h1>
                    </div>
                    <div className="col-12 col-md-9">
                        <Breadcrumb step={3}/>
                    </div>
                    <ShippingSummary/>
                    <div className="col-12">
                        <h2 className="title-h2">Documents</h2>
                    </div>
                    <PrintOrderSummary editable={false}/>
                    <DisplayOrderProcess/>
                </div>
            </div>
        </div>
    );
}

export default CheckoutContainer;