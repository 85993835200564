import React from "react";

const Breadcrumb = (props) => (
    <div className="breadcrumb">
        <ul className="breadcrumb__ul">
            <li className={"breadcrumb__li"+(props.step===1?' breadcrumb__li--active':'')}>01. Envoi des documents</li>
            <li className={"breadcrumb__li"+(props.step===2?' breadcrumb__li--active':'')}>02. Informations de livraison</li>
            <li className={"breadcrumb__li"+(props.step===3?' breadcrumb__li--active':'')}>03. Paiement</li>
        </ul>
        <div className="breadcrumb__container-lines">
            <span className="breadcrumb__line" style={{left: (props.step-1)*(100/3)+'%'}}>&nbsp;</span>
        </div>
    </div>
);

export default Breadcrumb;