import React from "react";
import logo from '../assets/img/logo.png';

const Footer = () => {
    return (
        <footer className="footer" id="footer">
            <div className="container">
                <div className="row footer__title-informations">
                    <div className="footer__important-title col-12 col-md-4">
                        <a className="logo__link footer__logo" href="/">
                            <img src={logo} alt="" className="logo__image img" />
                        </a>
                        <h2 className="footer__h2">Librairie Universitaire Polyvalente</h2>
                        <p className="footer__paragraph">
                            La DUC est une initiative de l’Agl, des Cercles Agro, Cesec, Crimino, Mds, Philo et Lettres,
                            Psycho, Maf, de l'EPHEC et de la Ciaco.
                        </p>
                        <p className="footer__paragraph">
                            Lundi - Jeudi : 10h - 18h30<br />
                            Vendredi & Samedi : 10h - 18h
                        </p>
                    </div>
                    <div className="col-12 col-md-8">
                        <div className="container">
                            <div className="row">
                                <div
                                    className="footer__informations footer__informations--followUs informations col-12 col-md-4">
                                    <h3 className="informations__h3">Informations</h3>
                                    <ul className="informations__list-item">
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/pages/librairie" target="_self"
                                               className="informations__link-contact informations__link-contact--instagram">Librairie</a>
                                        </li>
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/pages/syllabus" target="_self"
                                               className="informations__link-contact informations__link-contact--facebook">L'espace
                                                syllabus</a>
                                        </li>
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/pages/imprimantes" target="_self"
                                               className="informations__link-contact informations__link-contact--linkedin">Le
                                                parc d'imprimantes</a>
                                        </li>
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/dashboard" target="_self"
                                               className="informations__link-contact informations__link-contact--linkedin">Déposer
                                                vos documents</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer__informations informations col-12 col-md-4">
                                    <h3 className="informations__h3">F.A.Q.</h3>
                                    <ul className="informations__list-item">
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/pages/faq" target="_self" className="informations__link-contact">Support en
                                                ligne</a>
                                        </li>
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/pages/infos" target="_self" className="informations__link-contact">Informations
                                                pratiques</a>
                                        </li>
                                        <li className="informations__item">
                                            <a href="https://www.duc.be/pages/legal" className="informations__link-contact">Mentions légales</a>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className="footer__informations footer__informations--followUs informations col-12 col-md-4">
                                    <h3 className="informations__h3">Nous suivre</h3>
                                    <ul className="informations__list-item social-network">
                                        <li className="informations__item">
                                            <a href="http://www.facebook.com/duclln" target="_blank" rel="noopener noreferrer"
                                               className="informations__link-contact social-network__icon social-network__icon--facebook">Facebook</a>
                                        </li>
                                        <li className="informations__item">
                                            <a href="https://www.youtube.com/duclln" target="_blank" rel="noopener noreferrer"
                                               className="informations__link-contact social-network__icon social-network__icon--youtube">Youtube</a>
                                        </li>
                                        {/*<li className="informations__item">*/}
                                        {/*    <a href="?" target="_blank"*/}
                                        {/*       className="informations__link-contact social-network__icon social-network__icon--twitter">Twitter</a>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="footer__back-top" type="button">
                        goTop
                    </button>
                </div>
                <div className="footer__privacy-CGU privacy-CGU row">
                    <div className="privacy-CGU__item privacy-CGU__privacy col-12 col-md-6">
                        <span className="privacy-CGU__text-bottom">©</span>
                        <a href="http://www.kern-it.be" className="privacy-CGU__link-bottom">Kern-IT</a>
                        <span className="privacy-CGU__text-bottom">2022 All Rights Reserved</span>
                    </div>
                    <div className="privacy-CGU__item privacy-CGU__align-right col-12 col-md-6">
                        <span className="privacy-CGU__text-bottom">Design and Code By</span>
                        <a href="http://www.kern-it.be" className="privacy-CGU__link-bottom">Kern-IT</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;