import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { cancelPrintOrder } from 'actions/printOrders'


const CheckoutFailed = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleClick = () => {
		dispatch(cancelPrintOrder());
		history.push('/');
	}

	return (
		<div>
			<p className="error">Une erreur est survenue lors du paiement</p>
			<button onClick={handleClick}>Annuler la commande</button>
		</div>
	);
};

export default CheckoutFailed;