import axios from 'axios';
import { API_URL } from './settings';


axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token');
		if (token) {
			config.headers['X-Authorization'] = token;
		}
		return config;
	},
	error => {
		Promise.reject(error)
	}
);

export const addPrintOrder = async (data = null) => {
	const response = await axios.post(`${API_URL}/shop/orders`, data);
	return response.data;
};

export const fetchPrintOrder = async () => {
	if (localStorage.getItem('token')) {
		const response = await axios.get(`${API_URL}/shop/orders`);
		return response.data;
	}
	return null;
};

export const updatePrintOrder = async (data) => {
	const response = await axios.put(`${API_URL}/shop/orders`, data);
	return response.data;
};

export const confirmPrintOrder = async (data) => {
	try {
		const response = await axios.post(`${API_URL}/shop/orders/confirm`, data);
		return response.data
	} catch (e) {
		console.log(e)
	}
	return false
};

export const cancelPrintOrder = async (data) => {
	const response = await axios.post(`${API_URL}/shop/orders/cancel`);
	return response.data;
};

export const getPaymentIntentStatus = async (id) => {
	const response = await axios.get(`${API_URL}/shop/orders/payment_intents/${id}/status`);
	return response.data;
};

export const addLine = async ({ data, options }) => {
	const response = await axios.post(`${API_URL}/shop/orders/lines`, data, options);
	return response.data;
};

export const updateLine = async (lineId, data) => {
	const response = await axios.put(`${API_URL}/shop/orders/lines/${lineId}`, data);
	return response.data;
};

export const deleteLine = async (id) => {
	const response = await axios.delete(`${API_URL}/shop/orders/lines/${id}`);
	return response.data;
};
